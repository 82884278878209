import { KlimabeitragBenutaIcon } from '~/elements/Icons/KlimabeitragBenutaIcon'

export const SustainabilityLogo = () => {
  return (
    <div className="w-[162px] self-start text-center lg:text-start">
      <a
        href="https://fpm.climatepartner.com/tracking/23655-2312-1001/de"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Visit Benuta's Climate Partner contribution page"
      >
        <KlimabeitragBenutaIcon />
      </a>
    </div>
  )
}

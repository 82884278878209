import { SVGAttributes } from 'react'

export const QualityEmblemIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || 24}
      height={props.height || 24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity=".5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M5.106 18.894c-.863-.863-.29-2.674-.73-3.736C3.92 14.058 2.25 13.172 2.25 12s1.67-2.058 2.126-3.158c.44-1.062-.133-2.873.73-3.736s2.674-.29 3.736-.73C9.942 3.92 10.828 2.25 12 2.25s2.058 1.67 3.158 2.126c1.062.44 2.873-.133 3.736.73s.29 2.674.73 3.736c.455 1.1 2.126 1.986 2.126 3.158s-1.67 2.058-2.126 3.158c-.44 1.062.133 2.873-.73 3.736s-2.674.29-3.736.73c-1.1.455-1.986 2.126-3.158 2.126s-2.058-1.67-3.158-2.126c-1.062-.44-2.873.133-3.736-.73Z" />
        <path d="m16.125 9.75-5.5 5.25-2.75-2.625" />
      </g>
    </svg>
  )
}

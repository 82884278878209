import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { MasterCardIcon } from '~/elements/Icons/MasterCardIcon'
import { PaypalIcon } from '~/elements/Icons/PaypalIcon'
import { VisaIcon } from '~/elements/Icons/VisaIcon'
import { ApplePayIcon } from '~/elements/Icons/ApplePayIcon'
import { AmexIcon } from '~/elements/Icons/AmexIcon'
import { BancaireIcon } from '~/elements/Icons/BancaireIcon'
import { DHLIcon } from '~/elements/Icons/DHLIcon'
import { DPDIcon } from '~/elements/Icons/DPDIcon'
import { UPSIcon } from '~/elements/Icons/UPSIcon'
import { GELIcon } from '~/elements/Icons/GELIcon'

type Props = {
  className?: string
}

const getStoreIcons = () => {
  const paymentIcons = ['MasterCard', 'Paypal', 'Visa', 'ApplePay', 'Amex']
  const shippingIcons = ['DHL', 'DPD', 'UPS', 'GEL']

  if (process.env.NEXT_PUBLIC_STORE_CODE === 'benuta_fr') {
    return [...paymentIcons, 'Bancaire', ...shippingIcons]
  }

  return [...paymentIcons, ...shippingIcons]
}

const iconComponents = {
  MasterCard: MasterCardIcon,
  Paypal: PaypalIcon,
  Visa: VisaIcon,
  ApplePay: ApplePayIcon,
  Amex: AmexIcon,
  Bancaire: BancaireIcon,
  DHL: DHLIcon,
  DPD: DPDIcon,
  UPS: UPSIcon,
  GEL: GELIcon,
} as const

const chooseIcon = (icon: string) => {
  const IconComponent = iconComponents[icon as keyof typeof iconComponents]
  return <IconComponent />
}

export const DeliveryPay = ({ className }: Props) => {
  const { t } = useTranslation()
  const icons = getStoreIcons()

  return (
    <div className={clsx(className, 'flex flex-col')}>
      <p className="mb-7.5 whitespace-nowrap text-15-20-sg font-extralight text-primary-black">
        {t('Payment & Shipping Partners')}
      </p>
      <ul className="grid grid-cols-[repeat(auto-fit,40px)] gap-2.5 xl:max-w-[254px] xl:grid-cols-5">
        {icons.map((icon, index) => (
          <li title={icon} key={icons[index]}>
            {chooseIcon(icon)}
          </li>
        ))}
      </ul>
    </div>
  )
}

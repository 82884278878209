export const PaypalIcon = () => {
  return (
    <svg width="38" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.759" y="1.209" width="34.759" height="24" rx="4" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m17.603 13.363.151-.965-.337-.008h-1.61l1.119-7.122a.091.091 0 0 1 .09-.078h2.715c.901 0 1.523.189 1.848.56.152.175.249.357.296.557.049.21.05.462.002.768l-.004.023v.196l.152.087c.129.068.23.146.309.236.13.149.214.338.25.563.036.23.024.505-.036.817-.07.358-.182.67-.333.924-.14.235-.317.43-.529.581-.2.144-.44.253-.71.323a3.537 3.537 0 0 1-.89.103h-.21a.639.639 0 0 0-.629.538l-.016.087-.267 1.701-.012.063c-.004.02-.01.03-.017.036a.044.044 0 0 1-.028.01h-1.304Z"
        fill="#28356A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.17 7.12a4.808 4.808 0 0 1-.028.16c-.358 1.845-1.582 2.482-3.146 2.482h-.797a.387.387 0 0 0-.382.33l-.523 3.331a.204.204 0 0 0 .2.237h1.413a.34.34 0 0 0 .336-.288l.014-.072.266-1.694.017-.093a.34.34 0 0 1 .335-.288h.212c1.368 0 2.44-.558 2.752-2.172.131-.675.063-1.238-.283-1.634a1.349 1.349 0 0 0-.386-.299Z"
        fill="#298FC2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.795 6.97a2.804 2.804 0 0 0-.348-.078 4.409 4.409 0 0 0-.702-.051h-2.127a.34.34 0 0 0-.335.288l-.453 2.878-.013.084c.03-.19.19-.329.382-.329h.796c1.564 0 2.789-.638 3.147-2.483.01-.054.02-.107.028-.16a1.898 1.898 0 0 0-.375-.15Z"
        fill="#22284F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.282 7.13a.34.34 0 0 1 .336-.289h2.127c.252 0 .487.017.702.052a3.205 3.205 0 0 1 .348.077l.08.025c.106.036.204.077.295.125.106-.682-.001-1.146-.368-1.566-.405-.463-1.136-.661-2.07-.661h-2.715c-.19 0-.354.139-.383.329l-1.13 7.195a.234.234 0 0 0 .23.27h1.675l.873-5.558ZM9.606 15.321h-1.98a.275.275 0 0 0-.27.233l-.801 5.095c-.016.1.061.191.163.191h.945c.135 0 .25-.098.272-.233l.215-1.374A.275.275 0 0 1 8.422 19h.626c1.304 0 2.056-.634 2.253-1.889.088-.549.003-.98-.253-1.282-.281-.332-.78-.508-1.442-.508Zm.228 1.861c-.108.713-.65.713-1.175.713H8.36l.21-1.331a.165.165 0 0 1 .162-.14h.137c.358 0 .695 0 .869.204.104.122.136.304.096.554ZM15.572 17.12h-.948c-.08 0-.15.06-.163.14l-.042.267-.066-.097c-.205-.299-.663-.399-1.12-.399-1.046 0-1.94.797-2.115 1.914-.09.557.038 1.09.353 1.461.289.342.702.484 1.194.484.843 0 1.311-.544 1.311-.544l-.042.264a.165.165 0 0 0 .163.192h.853c.136 0 .25-.099.272-.233l.512-3.257a.165.165 0 0 0-.162-.191Zm-1.321 1.853a1.057 1.057 0 0 1-1.07.908c-.274 0-.494-.089-.636-.256-.14-.167-.193-.405-.148-.669a1.062 1.062 0 0 1 1.062-.916c.269 0 .487.09.632.26.144.17.201.41.16.673ZM20.546 17.202h-.953a.276.276 0 0 0-.227.121l-1.314 1.943-.556-1.867a.276.276 0 0 0-.264-.197h-.936a.166.166 0 0 0-.156.22l1.048 3.09-.986 1.398c-.077.11 0 .261.135.261h.951c.09 0 .175-.044.226-.118l3.167-4.59a.166.166 0 0 0-.135-.26Z"
        fill="#28356A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.72 15.321h-1.979a.275.275 0 0 0-.271.233l-.8 5.095c-.016.1.061.191.162.191h1.016c.094 0 .175-.069.19-.163l.227-1.444a.275.275 0 0 1 .271-.233h.626c1.304 0 2.056-.634 2.253-1.889.089-.549.004-.98-.253-1.282-.28-.332-.78-.508-1.441-.508Zm.229 1.861c-.108.713-.65.713-1.176.713h-.298l.21-1.331a.164.164 0 0 1 .163-.14h.137c.357 0 .694 0 .868.204.104.122.135.304.096.554ZM29.685 17.12h-.947c-.081 0-.15.06-.162.14l-.042.267-.067-.097c-.205-.299-.662-.399-1.119-.399-1.047 0-1.941.797-2.115 1.914-.09.557.037 1.09.352 1.461.29.342.702.484 1.194.484.844 0 1.311-.544 1.311-.544l-.042.264a.165.165 0 0 0 .164.192h.853c.135 0 .25-.099.271-.233l.513-3.257a.166.166 0 0 0-.164-.191Zm-1.32 1.853c-.092.543-.522.908-1.07.908-.275 0-.495-.089-.636-.256-.14-.167-.193-.405-.149-.669a1.063 1.063 0 0 1 1.062-.916c.27 0 .488.09.632.26.145.17.202.41.16.673ZM30.724 15.461l-.813 5.189c-.016.1.062.19.163.19h.816c.136 0 .251-.098.272-.232l.801-5.095a.165.165 0 0 0-.163-.192h-.914c-.08 0-.15.06-.162.14Z"
        fill="#298FC2"
      />
      <rect x="1.259" y=".709" width="35.759" height="25" rx="4.5" stroke="#CACACA" />
    </svg>
  )
}

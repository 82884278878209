import { SVGAttributes } from 'react'

export const ParcelReturnIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || 24}
      height={props.height || 24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity=".5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M7.484 9.348h-4.5v-4.5" />
        <path d="M6.166 17.834a8.25 8.25 0 1 0 0-11.668L2.984 9.348" />
      </g>
    </svg>
  )
}

import { Link } from 'elements/Link/Link'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { useRouter } from 'next/router'
import { useNavigationQuery } from '@contentfulTypes'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { ButtonMain } from '~/elements/Button/ButtonMain'
type Props = {
  className?: string
}
export const Copyright = ({ className }: Props) => {
  const { t } = useTranslation()
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useNavigationQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })
  return (
    <div>
      <ul
        className={clsx(
          'grid flex-wrap items-baseline gap-x-5 gap-y-4 font-extralight md:gap-x-10 xl:flex',
          className,
        )}
      >
        <li className="text-15-22-sg md:whitespace-nowrap">
          {t('Copyright')} &copy; {new Date().getFullYear()} {t('Benuta LLC')}
        </li>
        {data?.pageCollection?.items?.[0]?.footerMetaLinksCollection?.items?.map((item, i) => (
          <li key={item?.sys?.id || i}>
            <ButtonMain
              href={item?.linkInternalUrl as string}
              title={item?.title as string}
              className="whitespace-nowrap"
              variant="Text"
            >
              {item?.ctaText}
            </ButtonMain>
          </li>
        ))}
      </ul>
    </div>
  )
}

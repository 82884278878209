import clsx from 'clsx'
import { Accordion } from 'elements/Accordion/Accordion'
import { Link } from 'elements/Link/Link'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { useNavigationQuery } from '@contentfulTypes'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { Media } from '~/lib/Media'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  className?: string
}

declare const UC_UI: any

export const FooterLinks = ({ className }: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useNavigationQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })
  const footerColumns =
    data?.pageCollection?.items?.[0]?.footerColumnsCollection?.items?.[0]?.firstLevelItemsCollection
      ?.items
  if (!data || !data?.pageCollection?.items?.[0]?.footerColumnsCollection) return null
  const itemList = footerColumns?.map((item) => {
    return {
      itemHeading: item?.title || '',
      richText: (
        <ul className="grid gap-4 py-2">
          {item?.navigationListItemsCollection?.items?.map((subItem) => (
            <li key={subItem?.sys?.id} className="text-p">
              {subItem?.pageContent?.slug === '#uc-central-modal-show' ? (
                <a href="#uc-central-modal-show" onClick={() => UC_UI.showSecondLayer()}>
                  {subItem.title}
                </a>
              ) : (
                <Link href={subItem?.pageContent?.slug as string}>{subItem?.title}</Link>
              )}
            </li>
          ))}
        </ul>
      ),
    }
  })
  return (
    <>
      <Media lessThan="lg">
        <div className={clsx('', className)}>
          {itemList ? (
            <Accordion
              className="border-b border-sg-dark-grey"
              classNameHeadline="!font-medium"
              classNameButton="border-sg-dark-grey"
              itemList={itemList}
              itemHeadlineTagType="p"
            />
          ) : null}
        </div>
      </Media>
      <Media greaterThanOrEqual="lg">
        <div className={clsx('grid grid-cols-3 justify-between font-extralight', className)}>
          {footerColumns
            ? footerColumns.map((item) => (
                <div key={item?.sys.id}>
                  <p className="mb-6 text-20-25-sg">{item?.title}</p>
                  <ul className="grid gap-4">
                    {item?.navigationListItemsCollection?.items?.map((subItem) => (
                      <li className="text-15-15-sg" key={subItem?.sys.id}>
                        {subItem?.pageContent?.slug === '#uc-central-modal-show' ? (
                          <a href="#uc-central-modal-show" onClick={() => UC_UI.showSecondLayer()}>
                            {subItem.title}
                          </a>
                        ) : (
                          <Link href={subItem?.pageContent?.slug as string}>{subItem?.title}</Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            : null}
        </div>
      </Media>
    </>
  )
}

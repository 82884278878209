export const VisaIcon = () => {
  return (
    <svg width="38" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.517" y="1.209" width="34.759" height="24" rx="4" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.664 16.953h-2.041l-1.53-5.84c-.073-.268-.228-.506-.455-.617a6.644 6.644 0 0 0-1.87-.619v-.224h3.288c.453 0 .794.337.85.73l.795 4.212 2.04-4.942h1.985l-3.062 7.3Zm4.196 0h-1.928l1.588-7.3h1.928l-1.588 7.3Zm4.082-5.278c.056-.393.397-.618.794-.618a3.592 3.592 0 0 1 1.87.337l.341-1.572a4.88 4.88 0 0 0-1.758-.338c-1.87 0-3.232 1.012-3.232 2.415 0 1.068.964 1.628 1.645 1.966.737.337 1.02.562.963.898 0 .505-.567.73-1.133.73-.68 0-1.362-.168-1.985-.45l-.34 1.573c.68.28 1.417.394 2.098.394 2.098.055 3.402-.955 3.402-2.471 0-1.91-2.665-2.022-2.665-2.864Zm9.412 5.278-1.531-7.3h-1.644c-.34 0-.681.224-.794.561l-2.835 6.74h1.985l.396-1.068h2.438l.227 1.067h1.758Zm-2.892-5.334.567 2.751H26.44l1.021-2.751Z"
        fill="#172B85"
      />
      <rect x="1.017" y=".709" width="35.759" height="25" rx="4.5" stroke="#CACACA" />
    </svg>
  )
}

import clsx from 'clsx'
import { DeliveryCarIcon } from '~/elements/Icons/DeliveryCarIcon'
import { ParcelReturnIcon } from '~/elements/Icons/ParcelReturnIcon'
import { QualityEmblemIcon } from '~/elements/Icons/QualityEmblemIcon'
import { RugIcon } from '~/elements/Icons/RugIcon'

const iconComponents = {
  'parcel-return': ParcelReturnIcon,
  'delivery-car-big': DeliveryCarIcon,
  'quality-emblem': QualityEmblemIcon,
  rug: RugIcon,
} as const

const chooseIcon = (icon: string) => {
  const IconComponent = iconComponents[icon as keyof typeof iconComponents]

  return <IconComponent />
}

type IconBarItemProps = {
  data: { icon?: string | null; boldText?: string | null; normalText?: string | null }
  isLast: boolean
}

export const IconBarItem = ({ data }: IconBarItemProps) => {
  return (
    <div
      className={clsx(
        'relative flex w-full flex-col items-center p-4',
        // Right borders
        '[&:nth-child(1)]:after:absolute [&:nth-child(1)]:after:right-0 [&:nth-child(1)]:after:top-0 [&:nth-child(1)]:after:bottom-5 [&:nth-child(1)]:after:w-[1px] [&:nth-child(1)]:after:bg-sg-grey',
        '[&:nth-child(3)]:after:absolute [&:nth-child(3)]:after:right-0 [&:nth-child(3)]:after:top-5 [&:nth-child(3)]:after:bottom-0 [&:nth-child(3)]:after:w-[1px] [&:nth-child(3)]:after:bg-sg-grey',
        'xl:after:absolute xl:after:right-0 xl:after:top-0 xl:after:bottom-0 xl:after:w-[1px] xl:after:bg-sg-grey xl:last:after:hidden xl:[&:nth-child(1)]:after:bottom-0 xl:[&:nth-child(3)]:after:top-0',
        // Bottom borders
        '[&:nth-child(1)]:before:absolute [&:nth-child(1)]:before:bottom-0 [&:nth-child(1)]:before:left-0 [&:nth-child(1)]:before:right-5 [&:nth-child(1)]:before:h-[1px] [&:nth-child(1)]:before:bg-sg-grey',
        '[&:nth-child(2)]:before:absolute [&:nth-child(2)]:before:bottom-0 [&:nth-child(2)]:before:left-5 [&:nth-child(2)]:before:right-0 [&:nth-child(2)]:before:h-[1px] [&:nth-child(2)]:before:bg-sg-grey',
        'xl:[&:nth-child(1)]:before:hidden xl:[&:nth-child(2)]:before:hidden',
      )}
    >
      {data.icon && (
        <span className="mb-4 flex items-center justify-center">{chooseIcon(data.icon)}</span>
      )}
      <div className="flex max-w-[255px] flex-col gap-2">
        {data.boldText && (
          <p className="text-center text-12-12-sg font-extralight lg:text-15-15-sg">
            {data.boldText}
          </p>
        )}
        {data.normalText && (
          <p className="text-center text-10-16-sg font-extralight opacity-70 lg:text-12-20-sg lg:opacity-100">
            {data.normalText}
          </p>
        )}
      </div>
    </div>
  )
}

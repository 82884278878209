import { SVGAttributes } from 'react'

export const DeliveryCarIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || 24}
      height={props.height || 24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity=".5" stroke="currentColor">
        <path
          d="M22.5 11.25h-6V7.5h3.992a.75.75 0 0 1 .697.471L22.5 11.25ZM1.5 13.5h15"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.625 20.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5ZM6.375 20.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
          strokeMiterlimit="10"
        />
        <path
          d="M15.375 18h-6.75M4.125 18H2.25a.75.75 0 0 1-.75-.75V6.75A.75.75 0 0 1 2.25 6H16.5v10.051M16.5 16.051V11.25h6v6a.75.75 0 0 1-.75.75h-1.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

import { SVGAttributes } from 'react'

export const RugIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || 24}
      height={props.height || 24}
      fill={props.fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity=".5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M14.625 9.375h-5.25v5.25h5.25v-5.25Z" />
        <path d="M18.75 4.5H5.25a.75.75 0 0 0-.75.75v13.5c0 .414.336.75.75.75h13.5a.75.75 0 0 0 .75-.75V5.25a.75.75 0 0 0-.75-.75ZM19.5 9.75h2.25M19.5 14.25h2.25M2.25 9.75H4.5M2.25 14.25H4.5M14.25 19.5v2.25M9.75 19.5v2.25M14.25 2.25V4.5M9.75 2.25V4.5" />
      </g>
    </svg>
  )
}

export const UPSIcon = () => {
  return (
    <svg
      width="38"
      height="27"
      viewBox="0 0 38 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="38" height="27" fill="url(#pattern0_1933_22411)" />
      <defs>
        <pattern
          id="pattern0_1933_22411"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_1933_22411" transform="scale(0.0263158 0.037037)" />
        </pattern>
        <image
          id="image0_1933_22411"
          width="38"
          height="27"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAbCAYAAAAQ2f3dAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAJqADAAQAAAABAAAAGwAAAADdQ1xsAAAEUUlEQVRYCcWXy28bVRSHf7bH7zhx7cnDdhLbpE2d4LR5mATUVMQibAABQmWDKgFiwQ6pLNjxL1BYskIV6gI2CLUSJQoMjUGRDBVxSkuD+0ghNm3suI49npc9Hu4MslWjSPUEubnS+N577vE5n+7zHAP2KKurq6E9xB0RCYJQjMfjxf8aNzQEKozBYDhrNBoXrFaruyHvdF2r1UC+TUVRfuB5/kwDUgNToUwm069+v989MDAA0u40T4t9SRKRyWSRy+U2OY6bUuGMqgaZJWZ4eNgdCAQeO5Tq32KxIhwOw+fzhex2+1lVZkgkEgs0TTORSETtH2iRZRmpVAqlUumQkaKoBZfLdaBADefqFiL7G4RpkmoI9dYStwt25y5kgYdU+Atm8BAqHGxOB0SDE07fYXiGjuk129xKusGqxQ0Yi+uocVW4nSOoVlmI4gOIO7dhEQvkhLFQaiWUUyz4QzS2cRSR59+DvadPF6RusN0bn8HrHYTV0a85EsmJSiZXcO3adWxl7iBf5FCq1LSxbieF46Mr6A1NYjB2qrNgDev8bhnLFz7C5UQCxTKPCllGUZQaw1pdKMkQakqLrN2O7hlTDd/cuInvmN/xx40NCFWgXq+3669tPd1gD7YLuM8GQJG7h/a6YbVR4EUZRwIW5PNFfP/TLc35ySkvKpwAg9HcNszDirrBFJMdIZ8MvtgDb08Q0WgYQikPu7ECW92JgFe7s+Hr2sXfeQo73P6W8l8rD6M+oi3LtT2WTsGV9SzOX9zAbNQNp0XE/aIBny/tIl9uPsePsNw6rBtM/Xu9WoJSFci1IJIOB8ikJiWf52EjK/fjVQHBfgUfvNEHu3V/765usHq9BoUvE7gKAeNJuwRUVTgBNG0nV4WE9GYBH39ZgM0KjPjJzz6K7j3mJN7u3cpidrwLvOAAV8kTJgHT0V7MRSgk17dxajEAl12BneJQYOswmS260XSDVSkPLl2+hN+u22A2y/gl9SdOvzQCkWfB/JzF6loO/t4uRIYpLOUMeOpJMySTt/NgrqGTmBhnsLyShkDeyQpZOlHkkL7LalAqQTbHkg/wdFvh7h1DMHpCN5juPeYJz+PwdBwzY3TzdN7L8WBZvsW502HF6VfHcfz1T1rk7XZ0L6Ua1I3E34cnMA3f6Le4+PVXOP/NVtOfCnQiFsTcVBThFz6ENzjZHNPT0A2mGu9ydaNr5mX0jz2LY888hytLX2hv5kTEj/FRH2yhF/HE06/A5vLoYWnRpUgSUNzvW2dx9GBo9jXQR+cxPX8BkoXG4OQibA4nri5/ir7gDPqPxFocttuhSDi7RkLZdvX31FNjreGFd1rGJhbfbem301GTknK5rGZNa5p+Mplktra2yOQdbEmn0wph0ZIRbY+RWXs7k8kwkiSFemkvLCTufpxFEkUSZGbVJISZm5s7o/puvrAMw7hJIvAWSQjeJPL/m/DqDSk2id9zsVjsXGNC/gGbCCyJyPyjnAAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  )
}

import { IconBar } from './components/IconBar'
import { useRouter } from 'next/router'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { RteRteLinks, useBanderoleQuery } from '@contentfulTypes'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { Logo } from '../Header/components/Logo'
import { FooterLinks } from './components/FooterLinks'
import { DeliveryPay } from './components/DeliveryPay'
import { SustainabilityLogo } from './components/SustainabilityLogo'
import { SocialLinks } from './components/SocialLinks'
import { Copyright } from './components/Copyright'
import { RTEParser } from '~/lib/RTEParser/RTEParser'
import { useNewsletterData } from '../Newsletter/hooks/useNewsletterData'
import { NewsletterContent } from '../Newsletter/components/NewsletterContent'
import { useInView } from 'react-intersection-observer'

export const Footer = () => {
  const router = useRouter()
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const { data } = useBanderoleQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })

  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  const { headline, benefits } = useNewsletterData()

  const showSustainabilityLogo =
    process.env.NEXT_PUBLIC_STORE_CODE === 'benuta_de' ||
    process.env.NEXT_PUBLIC_STORE_CODE === 'benuta_at' ||
    process.env.NEXT_PUBLIC_STORE_CODE === 'benuta_chde'

  return (
    <footer ref={ref} className="print:hidden">
      <IconBar />
      <div className="flex w-full justify-center bg-sg-sand py-[50px] px-4 lgx:px-20 lgx:py-[100px]">
        <div className="flex w-full max-w-[1354px] flex-col">
          <Logo className="mb-[50px] lg:mb-[100px]" />
          <div className="flex justify-between gap-10">
            <div className="flex-1">
              <FooterLinks />
              <div className="mt-[50px] lgx:mb-0 lgx:flex lgx:gap-10">
                {inView && <DeliveryPay />}
                {showSustainabilityLogo && (
                  <div className="mt-[50px]">{inView && <SustainabilityLogo />}</div>
                )}
                <SocialLinks className="mt-[50px] lgx:mt-0" />
              </div>
            </div>
            <div className="hidden w-[400px] md:block">
              <NewsletterContent
                headline={headline}
                subscriptionBenefits={benefits as string[]}
                isFooter
              />
            </div>
          </div>
          <div className="mt-[50px] grid lgx:mt-[100px] lgx:grid-cols-[1fr_400px] lgx:gap-[160px]">
            <Copyright />
            {data?.pageCollection?.items[0]?.footerNote && (
              <RTEParser
                className="mt-[50px] font-extralight lgx:mt-0 [&_p]:text-10-16-sg"
                rteJSONContent={data?.pageCollection?.items[0]?.footerNote?.rte?.json}
                links={data?.pageCollection?.items[0]?.footerNote?.rte?.links as RteRteLinks}
              />
            )}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

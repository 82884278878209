export const DHLIcon = () => {
  return (
    <svg width="38" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y=".209" width="34.759" height="24" rx="4" fill="#FC0" />
      <path
        d="m6.067 9.836-1.102 1.65h6.008c.303 0 .299.127.151.348-.15.224-.403.616-.557.844-.078.115-.218.327.249.327h2.456l.728-1.09c.452-.675.039-2.08-1.575-2.08H6.067Z"
        fill="#D3072A"
      />
      <path
        d="m4.519 15.21 2.214-3.314H9.48c.304 0 .3.128.152.349l-.56.84c-.079.116-.22.327.247.327h3.68c-.306.462-1.299 1.797-3.081 1.797h-5.4ZM17.198 13.411l-1.2 1.798H12.83l1.2-1.798h3.167ZM22.05 13.005h-7.747l2.119-3.17h3.165l-1.214 1.818h1.414l1.215-1.817h3.165l-2.117 3.169ZM21.778 13.411l-1.201 1.798H17.41l1.201-1.798h3.166ZM0 14.12h4.666l-.255.382H0v-.382ZM0 13.411h5.14l-.256.382H0v-.382ZM0 14.83h4.192l-.254.378H0v-.379ZM34.5 14.502h-4.648l.256-.382H34.5v.382ZM34.5 15.208l-5.12.001.253-.38H34.5v.38ZM30.58 13.411h3.92v.382h-4.174l.255-.382ZM28.552 9.836l-2.118 3.169H23.08l2.12-3.17h3.353ZM22.807 13.411s-.23.349-.343.516c-.398.592-.047 1.282 1.251 1.282H28.8L30 13.41h-7.193Z"
        fill="#D3072A"
      />
    </svg>
  )
}

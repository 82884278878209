import { Icon, Icons } from '~/elements/Icons/Icon'
import { Link } from 'elements/Link/Link'
import clsx from 'clsx'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useNavigationQuery } from '@contentfulTypes'
import { useRouter } from 'next/router'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { useTranslation } from 'next-i18next'
type Props = {
  className?: string
}
export const SocialLinks = ({ className }: Props) => {
  const { t } = useTranslation()
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useNavigationQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })
  const socialLinks = data?.pageCollection?.items?.[0]?.socialFooterIconsCollection
  if (!data || !socialLinks) return null

  return (
    <div className={clsx(className, 'items-center lg:items-start')}>
      <p className="mb-7.5 text-15-20-sg font-extralight text-primary-black">{t('Follow us')}</p>
      <ul className={clsx('flex gap-6')}>
        {socialLinks?.items?.map((item, i) => (
          <li key={item?.sys?.id || i} className={`${item?.icon}-icon`}>
            <Link href={item?.link as string} title={item?.title as string}>
              <Icon name={item?.icon as Icons} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export const ApplePayIcon = () => {
  return (
    <svg width="38" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.276" y="1.209" width="34.759" height="24" rx="4" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.81 10.165c-.253.303-.658.541-1.062.507-.05-.41.147-.844.379-1.113.253-.311.695-.533 1.053-.55.042.426-.122.844-.37 1.156Zm.366.588c-.356-.02-.681.109-.944.213-.17.067-.312.124-.421.124-.122 0-.271-.06-.439-.127-.219-.088-.47-.189-.733-.184a1.728 1.728 0 0 0-1.47.904c-.632 1.1-.165 2.73.446 3.626.3.443.658.93 1.13.913a1.34 1.34 0 0 0 .511-.139c.178-.076.363-.156.652-.156.278 0 .455.078.625.152.161.07.316.138.546.134.489-.009.797-.444 1.096-.887.323-.476.464-.94.486-1.011l.003-.009-.012-.005c-.108-.05-.933-.433-.94-1.458-.009-.86.654-1.296.758-1.365l.012-.008c-.421-.631-1.078-.7-1.306-.717Zm3.384 5.413v-6.65h2.465c1.272 0 2.161.888 2.161 2.184 0 1.297-.905 2.193-2.195 2.193h-1.412v2.273h-1.02Zm1.02-5.78h1.175c.885 0 1.39.478 1.39 1.319 0 .84-.505 1.322-1.394 1.322h-1.172v-2.64Zm6.973 4.982c-.27.52-.864.85-1.504.85-.948 0-1.61-.572-1.61-1.434 0-.853.64-1.344 1.825-1.416l1.272-.077v-.367c0-.541-.35-.836-.973-.836-.514 0-.89.27-.965.679h-.919c.03-.862.83-1.489 1.913-1.489 1.168 0 1.926.618 1.926 1.578v3.31h-.944v-.798h-.02Zm-1.23.06c-.544 0-.89-.264-.89-.67 0-.417.334-.66.97-.699l1.133-.072v.375c0 .623-.522 1.066-1.213 1.066Zm5.326.998c-.409 1.165-.877 1.549-1.871 1.549-.076 0-.329-.009-.388-.026v-.798c.064.009.22.018.3.018.45 0 .703-.192.86-.692l.092-.294-1.728-4.841h1.066l1.201 3.928h.021l1.201-3.928h1.037l-1.791 5.084Z"
        fill="#000"
      />
      <rect x=".776" y=".709" width="35.759" height="25" rx="4.5" stroke="#CACACA" />
    </svg>
  )
}

import { useTranslation } from 'next-i18next'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { useNavigationQuery } from '@contentfulTypes'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { IconBarItem } from './IconBarItem'

export const IconBar = () => {
  const { t } = useTranslation()
  const preview = usePreviewMode()
  const router = useRouter()
  const contentfulClient = createGraphQLClient({ preview })
  const { data } = useNavigationQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })

  const items = data?.pageCollection?.items?.[0]?.footerIconBarCollection?.items || []

  return (
    <section aria-label={t('Our four selling points')} className="content-width bg-sg-light-sand">
      <div className="grid max-w-[1414px] auto-cols-fr grid-cols-2 py-[30px] px-5 lgx:px-0 xl:grid-cols-4">
        {items.map(
          (item, index) =>
            item?.icon && <IconBarItem key={item.sys.id} data={item} isLast={!items[index + 1]} />,
        )}
      </div>
    </section>
  )
}
